import React from 'react';
import PropTypes from 'prop-types';
import FormattedText from '../../../01_atoms/FormattedText';

const FooterCopy = ({ copy = '' }) => <FormattedText className="footer-copy" text={copy} />;

FooterCopy.propTypes = {
  copy: PropTypes.string,
};

export default FooterCopy;
