import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FacebookIcon from '../../../../public/static/icons/social/social-media-facebook.svg';
import InstagramIcon from '../../../../public/static/icons/social/social-media-instagram.svg';
import TwitterIcon from '../../../../public/static/icons/social/social-media-twitter.svg';
import YoutubeIcon from '../../../../public/static/icons/social/social-media-youtube.svg';
import LinkedinIcon from '../../../../public/static/icons/social/social-media-linkedin.svg';
import TikTokIcon from '../../../../public/static/icons/social/social-media-tiktok.svg';

import s from './index.module.scss';

const providers = {
  facebook: {
    icon: <FacebookIcon />,
    alt: 'Follow us on Facebook',
  },
  instagram: {
    icon: <InstagramIcon />,
    alt: 'Follow us on Instagram',
  },
  twitter: {
    icon: <TwitterIcon />,
    alt: 'Follow us on Twitter',
  },
  youtube: {
    icon: <YoutubeIcon />,
    alt: 'Follow us on Youtube',
  },
  linkedin: {
    icon: <LinkedinIcon />,
    alt: 'Follow us on LinkedIn',
  },
  tiktok: {
    icon: <TikTokIcon />,
    alt: 'Follow us on TikTok',
  },
};

const FooterSocialLinks = ({ socialLinks = [] }) => {
  const [openedType, setOpenedType] = useState('');

  useEffect(() => {
    const checkClickOutside = (e) => {
      const element = document.getElementById(`footer-social-links-${openedType}`);
      if (openedType && e.composedPath().indexOf(element) === -1) {
        setOpenedType('');
      }
    };
    if (openedType) {
      document.addEventListener('click', checkClickOutside);
    }

    return () => {
      if (openedType) {
        document.removeEventListener('click', checkClickOutside);
      }
    };
  }, [openedType]);

  return (
    <div className={s['footer-social-links']}>
      <ul className={s['footer-social-links__list']}>
        {socialLinks.map((item) => {
          if (!providers[item.type]) {
            return null;
          }
          if (!item.accounts?.length) {
            return null;
          }
          return (
            <li className={s['footer-social-links__list-item']} key={item.type}>
              {item.accounts.length === 1 && (
                <a
                  href={item.accounts[0].nextLink.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${s['footer-social-links__icon']} ${s[`footer-social-links__icon-${item.type}`]}`}
                  aria-label={providers[item.type].alt}
                  data-analytics={`footer-social-links--${item.type}`}
                >
                  {providers[item.type].icon}
                </a>
              )}

              {item.accounts.length > 1 && (
                <>
                  <div
                    id={`footer-social-links-${item.type}`}
                    role="button"
                    tabIndex="0"
                    aria-haspopup="true"
                    rel="noopener noreferrer"
                    className={`${s['footer-social-links__icon']} ${s['footer-social-links__icon-with-dropdown']} ${openedType === item.type ? s['opened'] : ''} ${s[`footer-social-links__icon-${item.type}`]}`}
                    aria-label={providers[item.type].alt}
                    onClick={() => setOpenedType(item.type)}
                    onKeyDown={(e) => e.key === 'Enter' && setOpenedType(item.type)}
                  >
                    {providers[item.type].icon}
                  </div>
                  {openedType === item.type && (
                    <span className={s['footer-social-links__accounts']}>
                      {item.accounts.map((account) => (
                        <a
                          href={account.nextLink?.href || ''}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={s['footer-social-links__accounts-item']}
                          aria-label={account.label}
                          data-analytics={`footer-social-links--${item.type}`}
                          key={account.label}
                        >
                          {account.label}
                        </a>
                      ))}
                    </span>
                  )}
                </>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

FooterSocialLinks.propTypes = {
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

export default FooterSocialLinks;
