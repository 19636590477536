import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../01_atoms/Link';

const FooterSmallMenu = ({ links = [] }) => (
  <div className="footer-small-menu">
    <ul>
      {links.map((item) => (
        <li key={item.text}>
          {item.jsSnippet && (
            <a
              onClick={(e) => {
                e.preventDefault();
                try {
                  /* eslint-disable-next-line no-eval */
                  eval(item.jsSnippet);
                } catch (error) {
                  /* eslint-disable-next-line no-console */
                  console.log(error);
                }
              }}
              href="#"
              className="footer-small-menu--link"
            >
              {item.text}
            </a>
          )}
          {item.nextLink && (
            <Link {...item.nextLink}>
              <a className="footer-small-menu--link">{item.text}</a>
            </Link>
          )}
        </li>
      ))}
    </ul>
  </div>
);

FooterSmallMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      nextLink: PropTypes.shape({
        href: PropTypes.string,
        as: PropTypes.string,
      }),
      jsSnippet: PropTypes.string,
    }),
  ),
};

export default FooterSmallMenu;
