import React, { useState, useEffect } from 'react';
import TrackVisibility from 'react-on-screen';
import Script from 'next/script';

/**
 * Google Translate widget for websites.
 *
 * The widget is loaded when:
 * 1. Preference consent is obtained (if Cookiebot is available on the page)
 * 2. And
 *   2.1 Either a user is already using Google Translate widget (cookie "googtrans")
 *   2.2 OR the widget is currently visible in viewport (user scrolled the page).
 */
const GoogleTranslateWidget = () => {
  const [isEnabledByUser, setEnabledByUser] = useState(false);

  useEffect(() => {
    if (/googtrans=/.test(document.cookie)) {
      setEnabledByUser(true);
    }
  }, [isEnabledByUser, setEnabledByUser]);

  return (
    <TrackVisibility
      once
      partialVisibility
      offset={200}
      className="google-translate-widget flex-lg-fill cookieconsent-optin-preferences"
    >
      {({ isVisible }) => (
        <>
          {(isEnabledByUser || isVisible) && (
            <>
              <Script
                id="google-translate-script"
                dangerouslySetInnerHTML={{
                  __html:
                    "function googleTranslateElementInit() { new google.translate.TranslateElement({pageLanguage: 'en', layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL, autoDisplay: false }, 'google_translate_element');}",
                }}
              />
              <Script
                id="google-translate-element-script"
                src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
              />
              <div id="google_translate_element" />
              <div className="google-powered-by">
                <a href="https://translate.google.com/" target="_blank" rel="noopener noreferrer">
                  Powered by{' '}
                  <img
                    src="/static/icons/logos/google-logo.png"
                    alt="Powered by Google Translate"
                    height="18"
                    width="53"
                  />
                </a>
              </div>
            </>
          )}
        </>
      )}
    </TrackVisibility>
  );
};

export default GoogleTranslateWidget;
